.brand {
  font-family: 'Comfortaa', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 64em) {
  .visible-lg {
    display: none;
  }

  .visible-sm {
    display: block;
  }
}

@media (min-width: 64em) {
  .visible-sm {
    display: block;
  }

  .visible-sm {
    display: none;
  }
}

.privacyMode :not(label) {
    color: transparent;
    text-shadow: 0 0 12px rgba(0,0,0,0.8);
}